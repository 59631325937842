import moment from 'moment';

import {
    SET_TANK_DATA,
    SET_TANK_FILTER_OPTIONS,
    SET_TANK_FILTERS,
    SET_TANK_DATA_LOADING,
    RESET_TANK_FILTERS,
    SET_TANK_PROPERTY_ID
} from '@/redux/mutations';

export const initialState = {
    property_id: null,
    data: {},
    loading: true,
    optionsFilters: {
        years: [moment().year()]
    },
    selectedFilters: {
        sectors: ['Todos'],
        years: [moment().year()]
    }
};

const indicatorsPanelTankReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_TANK_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_TANK_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_TANK_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_TANK_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_TANK_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_TANK_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    sectors: ['Todos'],
                    years: [moment().year()]
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelTankReducer;
