import { months } from 'constants/date';
import moment from 'moment';

import {
    SET_MILK_PRODUCTION_DATA,
    SET_MILK_PRODUCTION_FILTER_OPTIONS,
    SET_MILK_PRODUCTION_FILTERS,
    SET_MILK_PRODUCTION_DATA_LOADING,
    RESET_MILK_PRODUCTION_FILTERS,
    SET_MILK_PRODUCTION_PROPERTY_ID
} from '@/redux/mutations';

export const initialState = {
    property_id: null,
    data: {},
    loading: true,
    optionsFilters: {
        years: [],
        months: months,
        sectors: ['Todos']
    },
    selectedFilters: {
        sectors: ['Todos'],
        years: []
    }
};

const indicatorsPanelMilkProductionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_MILK_PRODUCTION_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_MILK_PRODUCTION_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_MILK_PRODUCTION_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_MILK_PRODUCTION_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_MILK_PRODUCTION_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_MILK_PRODUCTION_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    sectors: ['Todos'],
                    years: [moment().year()]
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelMilkProductionReducer;
